import React from "react"
import tw, { styled } from "twin.macro"

import { maximize } from '../livechat'
import Layout from "../components/Layout"
import ContentContainer from "../components/ContentContainer"
import TextContainer from "../components/TextContainer"
import Seo from '../components/Seo'

const Button = styled.button`
  ${tw`py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 shadow-sm focus:outline-none focus:shadow-outline-blue active:bg-red-600 transition duration-150 ease-in-out`}
`

const Contact = () => {
  return (
    <Layout>
      <Seo title="Kontakt" description="Zadaj pytanie o kurs - napisz maila, zadzwoń lub napisz na czacie" />
      <ContentContainer>
        <TextContainer>
          <h1>Kontakt</h1>
          <p>Aby się z nami skontaktować:</p>
          <ul>
            <li><strong>napisz maila</strong> na adres kontakt@kursyfinansowe.com.pl</li>
            <li><strong>zadzwoń</strong> pod numer 512 013 439</li>
            <li><strong>rozpocznij czat</strong> korzystając z widgetu w prawym dolnym rogu ekranu.</li>
          </ul>
          <Button onClick={maximize}>Rozpocznij czat</Button>
        </TextContainer>
      </ContentContainer>
    </Layout>
  )
}

export default Contact
